import { lazy } from 'react';

import { RouteProps } from 'react-router-dom';

import {
	adminPages,
	authPages,
	configurationPages,
	dashboardPages,
	forumPages,
	landingPages,
	pricingPages,
} from '../menu';

const LANDING = {
	LANDING: lazy(() => import('../pages/landing/LandingPage')),
	STOCKDATA: lazy(() => import('../pages/landing/StockDataPage')),
	INDICATORS: lazy(() => import('../pages/landing/IndicatorsPage')),
	PATTERNS: lazy(() => import('../pages/landing/PatternsPage')),
	WATCHLISTS: lazy(() => import('../pages/landing/WatchlistsPage')),
	INTERESTS: lazy(() => import('../pages/landing/InterestsPage')),
	CHARTS: lazy(() => import('../pages/landing/ChartsPage')),
	NEWS: lazy(() => import('../pages/landing/News')),
	TERMSOFUSE: lazy(() => import('../pages/landing/TermsOfUsePage')),
};

const DASHBOARD = {
	MAIN: lazy(() => import('../pages/dashboard/DashboardPage')),
	ORDERFLOW: lazy(() => import('../pages/dashboard/OrderFlowPage')),
	DARKPOOL: lazy(() => import('../pages/dashboard/DarkpoolPage')),
	DAILYWATCHLIST: lazy(() => import('../pages/dashboard/DailyWatchlistPage')),
	SCANNERS: lazy(() => import('../pages/dashboard/ScannersPage')),
	NEWS: lazy(() => import('../pages/dashboard/NewsPage')),
	CALENDAR: lazy(() => import('../pages/dashboard/CalendarPage')),
	GAMMA: lazy(() => import('../pages/dashboard/GammaPage')),
	NETPREMIUM: lazy(() => import('../pages/dashboard/NetPremium')),
	TUTORIALS: lazy(() => import('../pages/education/TutorialsPage')),
	VIDEOS: lazy(() => import('../pages/education/VideosPage')),
	SCRIPTS: lazy(() => import('../pages/education/ScriptsPage')),
};

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/Login')),
	PAGE404: lazy(() => import('../pages/auth/Page404')),
};

const UPGRADE = {
	MAIN: lazy(() => import('../pages/upgrade/UpgradePage')),
};

const CONFIGURATION = {
	ME: lazy(() => import('../pages/configuration/MePage')),
	SUBSCRIPTIONS: lazy(() => import('../pages/configuration/SubscriptionsPage')),
	CONFIGURATION: lazy(() => import('../pages/configuration/ConfigurationPage')),
	CHANGEPASSWORD: lazy(() => import('../pages/configuration/ChangePasswordPage')),
};

const FORUM = {
	FORUM: lazy(() => import('../pages/forums/Forums')),
	TRENDING: lazy(() => import('../pages/forums/Trending')),
	OURANALYSTS: lazy(() => import('../pages/forums/OurAnalysts')),
	CATEGORYVIEW: lazy(() => import('../pages/forums/CategoryView')),
	SUBCATEGORYVIEW: lazy(() => import('../pages/forums/SubCategoryView')),
	POSTVIEW: lazy(() => import('../pages/forums/PostView')),
	TOPICVIEW: lazy(() => import('../pages/forums/TopicView')),
	TOPICS: lazy(() => import('../pages/forums/Topics')),
};

const ADMIN = {
	ADMIN: lazy(() => import('../pages/admin/index')),
	RESTARTUSERPASSWORD: lazy(() => import('../pages/admin/resetUserPassword')),
	DASHBOARD: lazy(() => import('../pages/admin/Dashboard')),
};

const dashboard: RouteProps[] = [
	/* Start Auth */
	{ path: authPages.login.path, element: <AUTH.LOGIN /> },
	{ path: authPages.signUp.path, element: <AUTH.LOGIN isSignUp /> },
	/* Start Auth */

	/* Start Upgrade */
	{ path: pricingPages.pricing.path, element: <UPGRADE.MAIN /> },
	/* End Upgrade */

	/* Start Landing */
	{ path: landingPages.landing.path, element: <LANDING.LANDING /> },
	{ path: landingPages.stockdata.path, element: <LANDING.STOCKDATA /> },
	{ path: landingPages.indicators.path, element: <LANDING.INDICATORS /> },
	{ path: landingPages.patterns.path, element: <LANDING.PATTERNS /> },
	{ path: landingPages.watchlists.path, element: <LANDING.WATCHLISTS /> },
	{ path: landingPages.interests.path, element: <LANDING.INTERESTS /> },
	{ path: landingPages.charts.path, element: <LANDING.CHARTS /> },
	{ path: landingPages.news.path, element: <LANDING.NEWS /> },
	{ path: landingPages.termsofuse.path, element: <LANDING.TERMSOFUSE /> },
	/* End Landing */

	/* Start Dashboard */
	{ path: dashboardPages.dashboard.path, element: <DASHBOARD.MAIN /> },
	{ path: dashboardPages.orderFlow.path, element: <DASHBOARD.ORDERFLOW /> },
	{ path: dashboardPages.darkpool.path, element: <DASHBOARD.DARKPOOL /> },
	{ path: dashboardPages.dailyWatchlist.path, element: <DASHBOARD.DAILYWATCHLIST /> },
	{ path: dashboardPages.scanners.path, element: <DASHBOARD.SCANNERS /> },
	{ path: dashboardPages.news.path, element: <DASHBOARD.NEWS /> },
	// { path: dashboardPages.calendar.path, element: <DASHBOARD.CALENDAR /> },
	{ path: dashboardPages.gamma.path, element: <DASHBOARD.GAMMA /> },
	//{ path: dashboardPages.netPremium.path, element: <DASHBOARD.NETPREMIUM /> },

	{ path: dashboardPages.tutorials.path, element: <DASHBOARD.TUTORIALS /> },
	{ path: dashboardPages.videos.path, element: <DASHBOARD.VIDEOS /> },
	{ path: dashboardPages.scripts.path, element: <DASHBOARD.TUTORIALS /> },
	/* End Dashboard */

	/* Start configuration */
	{ path: configurationPages.me.path, element: <CONFIGURATION.ME /> },
	{ path: configurationPages.subscriptions.path, element: <CONFIGURATION.SUBSCRIPTIONS /> },
	{ path: configurationPages.configuration.path, element: <CONFIGURATION.CONFIGURATION /> },
	{ path: configurationPages.changePassword.path, element: <CONFIGURATION.CHANGEPASSWORD /> },
	/* End configuration */

	/* Start forum */
	{ path: forumPages.forum.path, element: <FORUM.FORUM /> },
	{ path: forumPages.trending.path, element: <FORUM.TRENDING /> },
	{ path: forumPages.ourAnalysts.path, element: <FORUM.OURANALYSTS /> },
	{ path: forumPages.forumCategory.path, element: <FORUM.CATEGORYVIEW /> },
	{ path: forumPages.forumSubCategory.path, element: <FORUM.SUBCATEGORYVIEW /> },
	{ path: forumPages.forumPost.path, element: <FORUM.POSTVIEW /> },
	{ path: forumPages.topics.path, element: <FORUM.TOPICS /> },
	{ path: forumPages.forumTopic.path, element: <FORUM.TOPICVIEW /> },
	/* End forum */

	/* Start Admin */
	{ path: adminPages.admin.path, element: <ADMIN.ADMIN /> },
	{ path: adminPages.restartUserPassword.path, element: <ADMIN.RESTARTUSERPASSWORD /> },
	{ path: adminPages.dashboard.path, element: <ADMIN.DASHBOARD /> },
	/* End Admin */

	{
		path: authPages.page404.path,
		element: <AUTH.PAGE404 />,
		//status: 404,
	},
];

const contents = [...dashboard];

export default contents;
