import { RouteProps } from 'react-router-dom';

import {
	adminPages,
	authPages,
	configurationPages,
	dashboardPages,
	forumPages,
	landingPages,
	pricingPages,
} from '../menu';
import AdminAside from '../pages/_layout/_asides/AdminAside';
import DashboardAside from '../pages/_layout/_asides/DashboardAside';
import ForumAside from '../pages/_layout/_asides/ForumAside';

const asides: RouteProps[] = [
	/* Start Auth */
	{ path: authPages.login.path, element: null },
	{ path: authPages.signUp.path, element: null },
	{ path: authPages.page404.path, element: null },
	/* End Auth */

	/* Start Upgrade */
	{ path: pricingPages.pricing.path, element: null },
	/* End Upgrade */

	/* Start Landing */
	{ path: landingPages.landing.path, element: null },
	{ path: landingPages.stockdata.path, element: null },
	{ path: landingPages.indicators.path, element: null },
	{ path: landingPages.patterns.path, element: null },
	{ path: landingPages.watchlists.path, element: null },
	{ path: landingPages.interests.path, element: null },
	{ path: landingPages.charts.path, element: null },
	{ path: landingPages.news.path, element: null },
	{ path: landingPages.termsofuse.path, element: null },
	/* End Landing */

	/* Start Dashboard */
	{ path: dashboardPages.dashboard.path, element: <DashboardAside /> },
	{ path: dashboardPages.orderFlow.path, element: <DashboardAside /> },
	{ path: dashboardPages.darkpool.path, element: <DashboardAside /> },
	{ path: dashboardPages.dailyWatchlist.path, element: <DashboardAside /> },
	{ path: dashboardPages.scanners.path, element: <DashboardAside /> },
	{ path: dashboardPages.news.path, element: <DashboardAside /> },
	{ path: dashboardPages.gamma.path, element: <DashboardAside /> },
	//{ path: dashboardPages.netPremium.path, element: <DashboardAside /> },

	{ path: dashboardPages.tutorials.path, element: <DashboardAside /> },
	{ path: dashboardPages.videos.path, element: <DashboardAside /> },
	{ path: dashboardPages.scripts.path, element: <DashboardAside /> },
	/* End Dashboard */

	/* Start configuration */
	{ path: configurationPages.me.path, element: <DashboardAside /> },
	{ path: configurationPages.subscriptions.path, element: <DashboardAside /> },
	{ path: configurationPages.configuration.path, element: <DashboardAside /> },
	{ path: configurationPages.changePassword.path, element: <DashboardAside /> },
	/* End configuration */

	/* Start forum */
	{ path: forumPages.forum.path, element: <ForumAside /> },
	{ path: forumPages.trending.path, element: <ForumAside /> },
	{ path: forumPages.ourAnalysts.path, element: <ForumAside /> },
	{ path: forumPages.forumCategory.path, element: <ForumAside /> },
	{ path: forumPages.forumSubCategory.path, element: <ForumAside /> },
	{ path: forumPages.forumPost.path, element: <ForumAside /> },
	{ path: forumPages.topics.path, element: <ForumAside /> },
	{ path: forumPages.forumTopic.path, element: <ForumAside /> },
	/* End forum */

	/* Start Admin */
	{ path: adminPages.admin.path, element: <AdminAside /> },
	{ path: adminPages.restartUserPassword.path, element: <AdminAside /> },
	{ path: adminPages.dashboard.path, element: <AdminAside /> },
	/* End Admin */

	{ path: '*', element: <DashboardAside /> },
];

export default asides;
